// File: SingleLocation.tsx

import { ScrollableNavigationBar } from "@/components/pure/navigation/dfp-scroll-navigation";
import { DB_ORG } from "@/constants/db";
import { updateOrganizationData } from "@/hooks/organization-queries";
import { HealthScorePill } from "@/layouts/signed-in/HealthScorePill/HealthScorePill";
import { Loader } from "@/layouts/signed-in/Loader/Loader";
import { AssetsTable } from "@/layouts/signed-in/views/Admin/AssetsTable/children/AssetsTable";
import { GeneralTab } from "@/layouts/signed-in/views/SingleLocation/GeneralTab/GeneralTab";
import { IGuideTab } from "@/layouts/signed-in/views/SingleLocation/IGuideTab/IGuide";
import { MatterportTab } from "@/layouts/signed-in/views/SingleLocation/MatterportTab/MatterportTab";
import { SettingsTab } from "@/layouts/signed-in/views/SingleLocation/SettingsTab/SettingsTab";
import { SubmissionsTab } from "@/layouts/signed-in/views/SingleLocation/SubmissionsTab/SubmissionsTab";
import { Viewer360Tab } from "@/layouts/signed-in/views/SingleLocation/Viewer360Tab/Viewer360Tab";
import { VirtualModal } from "@/layouts/signed-in/views/SingleLocation/VirtualModal/VirtualModal";
import { getLocation } from "@/services/location-service";
import { type IRole, useUserStore } from "@/stores/user-store";
import { LeftOutlined } from "@ant-design/icons";
import { Card, message } from "antd";
import { Button } from "antd";
import { FC, type ReactNode, useEffect, useMemo, useReducer } from "react";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { StatusIndicator } from "./single-location-status-icon";

// Types
interface LocationState {
	isLoading: boolean;
	isVerified: boolean;
	virtualTab: ReactNode | null;
	virtualChange: boolean;
	locationHealth: number | null;
	locInfo: any | null;
}

type LocationAction =
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_VERIFIED"; payload: boolean }
	| { type: "SET_VIRTUAL_TAB"; payload: ReactNode }
	| { type: "SET_VIRTUAL_CHANGE"; payload: boolean }
	| { type: "SET_LOCATION_INFO"; payload: { info: any; health: number | null } }
	| { type: "RESET_STATE" };

const initialState: LocationState = {
	isLoading: true,
	isVerified: true,
	virtualTab: null,
	virtualChange: false,
	locationHealth: null,
	locInfo: null,
};

function locationReducer(
	state: LocationState,
	action: LocationAction,
): LocationState {
	switch (action.type) {
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_VERIFIED":
			return { ...state, isVerified: action.payload };
		case "SET_VIRTUAL_TAB":
			return { ...state, virtualTab: action.payload };
		case "SET_VIRTUAL_CHANGE":
			return { ...state, virtualChange: action.payload };
		case "SET_LOCATION_INFO":
			return {
				...state,
				locInfo: action.payload.info,
				locationHealth: action.payload.health,
				isLoading: false,
			};
		case "RESET_STATE":
			return initialState;
		default:
			return state;
	}
}

interface SingleLocationProps {
	userRole: IRole;
}

let viewer = false;

export const SingleLocation: FC<SingleLocationProps> = ({
	userRole,
}: SingleLocationProps) => {
	const { id } = useParams<{ id: string }>();
	const { user } = useUserStore();
	const locationState = useLocation();
	const navigate = useNavigate();
	const [state, dispatch] = useReducer(locationReducer, initialState);

	// Define tabNames based on organization
	const tabNames = useMemo(() => {
		if (!user?.organization) return [];

		if (user.organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
			return ["Overview", "Virtual", "Submissions"];
		}

		const tabs = ["Overview", "Virtual", "Assets", "Submissions"];

		if (user.organization.external_firebase_id === DB_ORG.CVS) {
			tabs.push("Asset Map");
		}
		tabs.push("Settings");

		return tabs;
	}, [user?.organization]);

	const ADDRESS = useMemo(() => {
		if (!state.locInfo) return "";
		return `${
			state.locInfo.address ||
			`${state.locInfo.address1}${state.locInfo.address2 ? ` ${state.locInfo.address2}` : ""}`
		}, ${state.locInfo.city}, ${state.locInfo.state} ${state.locInfo.zip}`;
	}, [state.locInfo]);

	// Update location info when available
	useEffect(() => {
		const updateLocationInfo = async () => {
			const locationInfo = await getLocation(id as string);
			if (state.isVerified) {
				dispatch({
					type: "SET_LOCATION_INFO",
					payload: {
						info: locationInfo,
						health: locationInfo.health
							? Number(locationInfo.health.health_score.toFixed(1))
							: 0,
					},
				});
			}
		};
		updateLocationInfo();
	}, [state.isVerified, id]);

	// Handle virtual tab updates
	useEffect(() => {
		async function updateVirtualTab() {
			if (!user?.organization || !state.locInfo) return;

			const showMatterport = user.organization.config?.show_matterport || false;
			const showIguide = user.organization.config?.show_iguide || false;
			const externalFirebaseId = user.organization.external_firebase_id;
			const matterport = state.locInfo.matterport || null;
			const iguide = state.locInfo.iguide || null;
			const viewerData = state.locInfo.sphereViewerData || null;

			let newTab: ReactNode = null;

			if (matterport) {
				newTab = (
					<MatterportTab
						matterport={matterport}
						locationInfo={state.locInfo}
						setVirtualChange={(change: boolean) =>
							dispatch({ type: "SET_VIRTUAL_CHANGE", payload: change })
						}
						userRole={userRole}
					/>
				);
			} else if (iguide) {
				newTab = <IGuideTab iguide={iguide} />;
			} else if (viewer) {
				newTab = (
					<Viewer360Tab locationId={id} organization={externalFirebaseId} />
				);
			} else {
				newTab = (
					<Card className="h-100 w-100">
						<div className="d-flex flex-column align-items-center justify-content-evenly">
							<span
								className="mb-2 fs-4"
								style={{ textShadow: "2px 2px 12px rgba(0, 0, 0, 0.5)" }}
							>
								No Model Currently Connected.
							</span>
							{showMatterport && (
								<VirtualModal
									virtualType="Matterport"
									locationInfo={state.locInfo}
									setVirtualChange={(change: boolean) =>
										dispatch({ type: "SET_VIRTUAL_CHANGE", payload: change })
									}
								/>
							)}
							{showIguide && (
								<VirtualModal
									virtualType="iGuide"
									locationInfo={state.locInfo}
									setVirtualChange={(change: boolean) =>
										dispatch({ type: "SET_VIRTUAL_CHANGE", payload: change })
									}
								/>
							)}
							{viewerData && (
								<Button
									type="primary"
									style={{ width: "165px" }}
									onClick={() => {
										viewer = true;
										dispatch({ type: "SET_VIRTUAL_CHANGE", payload: true });
									}}
								>
									Connect 360 View
								</Button>
							)}
						</div>
					</Card>
				);
			}

			dispatch({ type: "SET_VIRTUAL_TAB", payload: newTab });
			dispatch({ type: "SET_VIRTUAL_CHANGE", payload: false });
		}

		updateVirtualTab();
	}, [state.virtualChange, state.locInfo, user?.organization, id, userRole]);

	const resetVirtual = async () => {
		if (!state.locInfo || !user?.organization) return;

		let virtual: string | null = null;
		if (state.locInfo.matterport) {
			virtual = "matterport";
		} else if (state.locInfo.iguide) {
			virtual = "iguide";
		}

		if (!virtual) return;

		const body = { [`${virtual}`]: "DELETE" };
		const result = await updateOrganizationData({
			id: state.locInfo.id,
			is_firebase_id: false,
			body,
		});

		if (result.success) {
			dispatch({ type: "SET_VIRTUAL_CHANGE", payload: true });
			message.success("Virtual ID has been reset");
		} else {
			message.error(result.error || "Failed to reset virtual ID");
		}
	};

	const goBackToLocations = () => {
		const stateProps = locationState?.state
			? {
					pageNum: locationState.state.pageNum,
					sortBy: locationState.state.sortBy,
					sortOrder: locationState.state.sortOrder,
					searchVal: locationState.state.searchVal,
				}
			: undefined;

		navigate("/locations", { state: stateProps });
	};

	if (state.isLoading || !user?.organization) {
		return <Loader />;
	}

	return state.isVerified ? (
		<div className="single-location-parent">
			<ScrollableNavigationBar
				tabNames={tabNames}
				basePath={`/locations/${id}`}
			/>

			<Card className="border-bottom-0 rounded-bottom-0 shadow-sm">
				<Button
					type="link"
					onClick={goBackToLocations}
					onKeyDown={goBackToLocations}
					icon={<LeftOutlined />}
					size="large"
					className="px-0"
				>
					Back
				</Button>

				{user.organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD && (
					<StatusIndicator isActive={state.locInfo?.is_active} />
				)}

				<div className="row align-items-between my-2">
					<div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
						<span className="fw-bold">{ADDRESS}</span>
					</div>
					{user.organization.external_firebase_id !==
						DB_ORG.CUSHMANWAKEFIELD && (
						<div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
							<div className="single-location-health-pill">
								<HealthScorePill
									healthScore={state.locationHealth || 0}
									size="lg"
								/>
							</div>
						</div>
					)}
				</div>
			</Card>

			<div className="fullSize">
				<Routes>
					<Route path="/" element={<Navigate to="overview" replace />} />
					<Route
						path="overview"
						element={
							<GeneralTab
								setToast={() => {}}
								healthScore={state.locationHealth}
								organization={user?.organization}
								info={state.locInfo}
								locationId={id}
								userRole={user?.role}
							/>
						}
					/>
					<Route path="virtual" element={state.virtualTab} />
					<Route
						path="assets"
						element={<AssetsTable browserLocationId={id || ""} />}
					/>
					<Route
						path="submissions"
						element={
							<SubmissionsTab
								browserLocationId={id as string}
								organization={user?.organization}
							/>
						}
					/>
					<Route
						path="settings"
						element={
							<SettingsTab userRole={user?.role} resetVirtual={resetVirtual} />
						}
					/>
					<Route path="*" element={<Navigate to="overview" replace />} />
				</Routes>
			</div>
		</div>
	) : null;
};
