import { AppInitLoader } from "@/components/loader/app-init-loader";
import { SubmissionDetail } from "@/components/pure/submission-detail/submissions-detail";
import { DB_ORG } from "@/constants/db";
import { useLogout } from "@/hooks/auth-queries";
import { Copyright } from "@/layouts/signed-in/Copyright/Copyright";
import { Navbar } from "@/layouts/signed-in/Navbar/Navbar";
import { Sidebar } from "@/layouts/signed-in/Sidebar/Sidebar";
import { LocationsTable } from "@/layouts/signed-in/views/Locations/LocationsTable";
import { selfUpdateUserComprehensive } from "@/services/user-service";
import { usePageStore, useUserStore } from "@/stores";
import { Admin } from "@/views/SignedIn/Admin/Admin";
import { CWDashboard } from "@/views/SignedIn/CWDashboard/CWDashboard";
import { Dashboard } from "@/views/SignedIn/Dashboard/Dashboard";
import { FormHandler } from "@/views/SignedIn/Forms/FormHandler";
import { Forms } from "@/views/SignedIn/Forms/Forms";
import { Plans } from "@/views/SignedIn/Plans/Plans";
import { PredictiveSpendNew } from "@/views/SignedIn/PredictiveSpend/PredictiveSpendNew";
import { Profile } from "@/views/SignedIn/Profile/Profile";
import { Releases } from "@/views/SignedIn/Releases/Releases";
import { SingleLocation } from "@/views/SignedIn/SingleLocation/SingleLocation";
import { message } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const SignedIn = () => {
	const { user, setUser } = useUserStore();
	const { isSidebarOpen, setIsSidebarOpen, setIsLoading } = usePageStore();
	const [dashboard, setDashboard] = useState<any>();

	const logout = useLogout();

	const handleLogout = async () => {
		try {
			const result = await logout();
			if (result.success) {
				message.success("Logged out successfully");
			} else {
				message.error(result.error || "Failed to logout");
			}
		} catch (error) {
			console.error("An error occurred during logout", error);
			message.error("An error occurred during logout");
		}
	};

	const updateUserOrg = async (orgId: string, _orgName: string) => {
		if (!user || user.organization?.external_firebase_id === orgId) return;

		const previousUser = user;
		const selectedOrg = user.allowed_organizations.find(
			(org) => org.external_firebase_id === orgId,
		);

		if (!selectedOrg) return;

		const updatedUser = {
			...user,
			organization_id: selectedOrg.id,
		};

		try {
			// Sync with backend and get complete user data
			setIsLoading(true);
			const response = await selfUpdateUserComprehensive(updatedUser);
			setUser(response);
			message.success("Organization has been changed");
		} catch (error: any) {
			// Rollback on failure
			setUser(previousUser);
			message.error(error.message || "Failed to update organization");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const getData = async () => {
			if (!user) return;
			if (user.organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
				setDashboard(<CWDashboard />);
			} else {
				setDashboard(<Dashboard />);
			}
		};
		getData();
	}, [user]);

	return (
		<div className="page m-0 vw-100">
			<Row className="m-0 vw-100">
				<Container fluid className="p-0 m-0 vw-100">
					<div className="d-flex flex-column flex-md-row">
						<Sidebar
							isSidebarOpen={isSidebarOpen}
							user={user!}
							updateUserOrg={updateUserOrg}
							logout={handleLogout}
						/>
						<Col
							className={
								isSidebarOpen
									? "px-2 pt-1 main-page-container min-vh-100"
									: "px-2 pt-1 main-page-container min-vh-100 main-page-close"
							}
						>
							<Navbar
								isSidebarOpen={isSidebarOpen}
								setIsSidebarOpen={setIsSidebarOpen}
							/>
							<Routes>
								<Route path="/" element={dashboard || <AppInitLoader />} />
								<Route path="/profile" element={<Profile />} />
								<Route path="/admin/*" element={<Admin />} />
								<Route path="/plans" element={<Plans />} />
								<Route path="/locations/*" element={<LocationsTable />} />
								<Route
									path={"/locations/:id/*"}
									element={
										<SingleLocation
											userRole={
												user?.role || { access_level: 100, id: 1, name: "User" }
											}
										/>
									}
								/>
								<Route
									path={"/locations/:id/submissions/:submissionId/"}
									element={<SubmissionDetail />}
								/>
								<Route path="/forms" element={<Forms />} />
								<Route
									path="/forms/:id/"
									element={<FormHandler user={user} />}
								/>
								<Route path="/releases" element={<Releases />} />
								<Route
									path="/predictive-spend"
									element={<PredictiveSpendNew />}
								/>
							</Routes>
							<div className="copyright d-flex mt-5">
								<Copyright />
							</div>
						</Col>
					</div>
				</Container>
			</Row>
		</div>
	);
};

export { SignedIn };
